async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');
    return Flickity;
}

importFlickity().then((Flickity) => {

    // call to update hidden slide aria attributes
    function setHeroSlide (hiddenSlides) {
        hiddenSlides.forEach((hiddenSlide) => {
            hiddenSlide.setAttribute('tabindex', '-1');
        });
    }

    Flickity.prototype._createResizeClass = function() {
        this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function() {
        this.element.classList.remove('flickity-resize');
        resize.call( this );
        this.element.classList.add('flickity-resize');
    };

    const carousel = document.querySelector('.news-carousel');
    const news = new Flickity(carousel, {
        // flickity event binding
        // https://flickity.metafizzy.co/events.html#ready
        on: {
            ready: () => {
                let hiddenSlides = document.querySelectorAll(".news-cell[aria-hidden='true']");
                setHeroSlide(hiddenSlides);
            }
        },
        accessibility: false,
        adaptiveHeight: false,
        cellAlign: 'left',
        contain: true,
        draggable: '>1',
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false,
        setGallerySize: false
    });

    let previousButton = document.querySelector('.news-next-prev.previous');
    if (previousButton) {
        previousButton.addEventListener('click', function () {
            news.previous();
        });
    }

    let nextButton = document.querySelector('.news-next-prev.next');
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            news.next();
        });
    }
});